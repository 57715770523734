import * as React from 'react';
// @ts-ignore
import sqlFormatter from 'sql-formatter';
import withI18next from '../../../plugins/gatsby-plugin-i18next/withI18next';
import Formatter from '../../components/tools/formatter';
import Layout from '../../layouts/tools';
import constants from '../../utils/constants';

const _ = require('lodash');
const styles = require('./sql.module.scss');

const Page = () => (
  <Layout currentPage={constants.PAGE_SQL}>
    <Formatter
      className={styles.formatter}
      currentPage={constants.PAGE_SQL}
      language={'sql'}
      format={(data: string, indent: string) => {
        return sqlFormatter.format(data, {
          indent,
          language: 'sql',
        });
      }}
    />
  </Layout>
);

export default withI18next()(Page);
